<template>
    <div class="row">
        <LeftSection :list_check_box="list_check_box" :links="data.data_links" />

        <!-- Right Section -->
        <div class="col-12 col-lg-9 wrapper-box-fiche">
            <template v-for="(item, key) in list_check_box"> 
                <div class="box-white-fiche" :key="key" v-if="show_block.includes(key) && item.enable" :id="key">
                    <!-- Section Title Start -->
                    <div class="box-icon-openbar">
                        <div class="box-click-open" @click="item.show = !item.show">
                            <h3 :class="item.orange_text ? 'text-orange' : ''" style="margin-bottom: 5px;">{{ $t(item.title) }}</h3>
                            <em :class="item.show ? 'fa fa-pull-right fa-angle-up arrow-up' : 'fa fa-pull-right fa-angle-down arrow-down'"></em>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                    <!-- Section Title End -->

                    
                    <!-- Section Detail Start -->
                     <!--information_general start-->
                    <InformationGeneral v-if="key == 'detail_information_general' && item.show" />
                    <!--information_general end-->
                  
                    <!--Registered indication start-->
                    <Indication v-else-if="key == 'detail_indication' && item.show" />
                    <!--Indication end-->

                    <!-- treatment_line start -->
                    <TreatmentLine v-else-if="key == 'treatment_line' && item.show"/>
                    <!-- treatment_line end -->


                    <table class="comparateur2 table-result text-left"   v-if="key == 'detail_conclusion3' && item.show" >
                        <tbody>
                            <tr>
                                <th width="50%" style="border-bottom:1px solid #fff;text-align: left" class="gray ">{{$t('zinl.HTA Agency conclusion on Economic Evidence')}}</th>
                                <td class="bg-white border-top-gray">{{ data.zinl['hta_agency_conclusion_on_economic_evidence'] ? $t('zinl.hta_agency_conclusion_on_economic_evidence_' + data.zinl['hta_agency_conclusion_on_economic_evidence']) : "-"}}</td>
                            </tr>
                            <tr>
                                <th class="gray" style="border-bottom:1px solid #fff;">{{$t('zinl.recommendation')}}</th>
                                <td class="bg-white">{{ data.zinl['recommendation'] ? $t('zinl.recommendation_' +data.zinl['recommendation']) : "-" }}</td>
                            </tr>
                            <tr>
                                <th colspan="2" class="text-center gray" style="border-bottom:1px solid #fff;">{{$t('zinl.Conditions for approval')}}</th>                        
                            </tr>
                            <tr>
                                <td colspan="2" class="bg-white"><span v-html="data.zinl['condition_for_approval']  ? data.zinl['condition_for_approval'] :'-' "></span></td>                        
                            </tr>
                            <tr>
                                <th colspan="2" class="text-center gray" style="border-bottom:1px solid #fff;">{{$t('zinl.Recommended indication')}}</th>                        
                            </tr>
                            <tr>
                                <td colspan="2" class="bg-white"><span v-html="data.zinl['recommended_indication'] ?  data.zinl['recommended_indication'] : '-'"></span></td>                                                    
                            </tr>
                        </tbody>
                    </table>

                    <!-- 'sub_detail/conclusion.ctp' -->
                    <Conclusion v-else-if="key == 'detail_Conclusion' && item.show" />  

                    <!--3Clinical rationale start-->                
                    <template  v-else-if="key == 'detail_clinical_rationale_safety_concerns' && item.show" >  

                        <table v-for="(items,keys) in data.zinl.zinl_clinical_rationale" class="comparateur2 table text-left tb-border" :key="'zinl_clinical_rationale'+keys">
                            <tbody>
                                <tr>
                                    <td style="border-right: 0; border-left: 0;border-top: 0;" class="bg-white" width="40%"></td>
                                    <td style="border-right: 0; border-left: 0;border-top: 0;" class="bg-white" width="30%"></td>
                                    <th  class="gray">{{$t('zinl.clinical_rationale_other')}}</th>
                                </tr>

                                <template v-if="items.zinl_comparator && items.zinl_comparator.length > 0 ">                                                            
                                        <tr v-for="(items_comparator, keys_comparator) in items.zinl_comparator" :key="'zinl_comparator'+keys_comparator">
                                            <th  class="gray" style="text-align: left;border-bottom: 1px solid #fff;" width="40%"><strong>{{$t('Comparator')}}</strong></th>
                                            <td class="bg-white textJustify">{{items_comparator['comparator']  ?  items_comparator['comparator'] :"-" }}</td>
                                            <td class="bg-white">{{items_comparator['accepted'] != 1  ? "-" : $t('zinl.Accepted by ZiN')}}</td>
                                        </tr>                                
                                </template>   

                                <tr v-if="items['clinical_rationale_safety_concerns'] != 0  || items['clinical_rationale_safety_concerns_text']">
                                    <th  class="gray" style="text-align: left;border-bottom: 1px solid #fff;" width="40%"><strong>{{$t('zinl.clinical_rationale_safety_concerns')}}</strong></th>
                                    <td class="bg-white textJustify">{{ $t('yes_no_' + items['clinical_rationale_safety_concerns'])}}</td>
                                    <td class="bg-white"> {{items['clinical_rationale_safety_concerns_text'] ? items['clinical_rationale_safety_concerns_text'] : '-'}} </td>
                                </tr>
                                <tr v-if="items['clinical_rationale_same_safety']  ||  items['clinical_rationale_same_safety_text']">
                                    <th  class="gray" style="text-align: left;border-bottom: 1px solid #fff;"  width="40%"><strong>{{$t('zinl.clinical_rationale_same_safety')}}</strong></th>
                                    <td class="bg-white textJustify">{{items['clinical_rationale_same_safety'] ? $t('zinl.'+items['clinical_rationale_same_safety']) : "-"}}</td>
                                    <td class="bg-white">{{items['clinical_rationale_same_safety_text'] ? items['clinical_rationale_same_safety_text'] : "-" }}</td>
                                </tr>                                
                                
                                <tr v-if="items['clinical_rationale_alternative_to_comparator_needed'] != 0 ||  items['clinical_rationale_alternative_to_comparator_needed_text']">
                                    <th class="gray" style="text-align: left;border-bottom: 1px solid #fff;"  width="40%"><strong>Alternative to comparator needed</strong></th>
                                    <td class="bg-white textJustify">{{ $t('yes_no_' +items['clinical_rationale_alternative_to_comparator_needed'])}}</td>
                                    <td class="bg-white"> {{items['clinical_rationale_alternative_to_comparator_needed_text'] ? items['clinical_rationale_alternative_to_comparator_needed_text'] : "-"}}</td>
                                </tr>
                            
                                <tr v-if="items['clinical_rationale_same_effect']  ||  items['clinical_rationale_same_effect_text']">
                                    <th  class="gray" style="text-align: left;border-bottom: 1px solid #fff;"  width="40%"><strong>{{$t('zinl.clinical_rationale_same_effect')}}</strong></th>
                                    <td class="bg-white textJustify">{{ items['clinical_rationale_same_effect'] ?  $t('zinl.'+items['clinical_rationale_same_effect'])  : '-'}}</td>
                                    <td class="bg-white">{{ items['clinical_rationale_same_effect_text'] ? items['clinical_rationale_same_effect_text'] : '-'}}</td>
                                </tr>                          

                                <tr v-if="items['clinical_rationale_primary_endpoints']">
                                    <th  class="gray" style="text-align: left;border-bottom: 1px solid #fff;"  width="40%"><strong>{{$t('zinl.clinical_rationale_primary_endpoints')}}</strong></th>
                                    <td colspan="2" class="bg-white textJustify">{{items['clinical_rationale_primary_endpoints']}}</td>
                                </tr> 
                            
                                <tr v-if="items['clinical_rationale_secondary_endpoints']">
                                    <th  class="gray" style="text-align: left;border-bottom: 1px solid #fff;"  width="40%"><strong>{{$t('zinl.clinical_rationale_secondary_endpoints')}}</strong></th>
                                    <td colspan="2" class="bg-white textJustify">{{items['clinical_rationale_secondary_endpoints']}}</td>
                                </tr>
                                <tr v-if="items['clinical_rationale_qol_outcomes']">
                                    <th  class="gray" style="text-align: left;border-bottom: 1px solid #fff;"  width="40%"><strong>{{$t('zinl.clinical_rationale_qol_outcomes')}}</strong></th>
                                    <td colspan="2" class="bg-white textJustify">{{ items['clinical_rationale_qol_outcomes'] }}</td>
                                </tr>
                        
                                <tr v-if="items['clinical_rationale_other']">
                                    <th  class="gray" style="text-align: left;border-bottom: 1px solid #fff;"  width="40%"><strong>{{ $t('zinl.clinical_rationale_other') }}</strong></th>
                                    <td colspan="2" class="bg-white textJustify"><span v-html="items['clinical_rationale_other']"></span></td>
                                </tr>
                                
                            </tbody>
                        </table>  
                    </template> 
                    <!--Clinical rationale end-->


                    <!--4Economic rationale start-->   
                    <template  v-else-if="key == 'detail_economic_rationale_same_price' && item.show" >  
 
                        <table v-for="(items , keys) in data.zinl.zinl_economic_rationale" class="comparateur2 table text-left tb-border" :key="'economic_rationale'+keys" >
                            <tbody>
                                <tr>
                                    <td style="border-right: 0; border-left: 0;border-top: 0;" width="40%"></td>
                                    <td style="border-right: 0; border-left: 0;border-top: 0;" width="30%"></td>
                                    <th>{{$t('zinl.clinical_rationale_other')}}</th>
                                </tr>
                        
                                    <tr v-if="items['economic_rationale_considered'] != 0">
                                        <th style="text-align: left;" width="40%"><strong>{{$t('zinl.economic_rationale_considered')}}</strong></th>
                                        <td class="textJustify">  {{ $t('yes_no_' + items['economic_rationale_considered']) }}</td>
                                        <td> {{ items['economic_rationale_considered_text']  ? items['economic_rationale_considered_text'] :'-' }}</td>
                                    </tr>
                            
                                    <tr v-if="items['economic_rationale_addtional'] != 0">
                                        <th style="text-align: left;" width="40%"><strong>Additional value related to administration</strong></th>
                                        <td class="textJustify"> {{ $t( 'yes_no_' + items['economic_rationale_addtional'], true) }}</td>
                                        <td> {{items['economic_rationale_addtional_text']  ?  items['economic_rationale_addtional_text'] :'-' }}</td>
                                    </tr>

                                    <tr v-if="items['economic_rationale_other']">
                                        <th style="text-align: left;" width="40%"><strong>{{$t('zinl.economic_rationale_other')}}</strong></th>
                                        <td colspan="2" class="textJustify"><span v-html="items['economic_rationale_other']"></span></td>
                                    </tr>
                                
                            </tbody>
                        </table> 
                    </template>
                    <!--Economic rationale end-->

                    <!--conclusion start-->    
                    <template v-else-if="key == 'detail_conclusion2' && item.show" >          
                        <span v-html="data.zinl['conclusion']"></span>                        
                        <table v-if="data.zinl['hta_agency_conclusion_on_economic_evidence']" class="comparateur2 table-result text-left" >
                            <tbody>
                                <tr>
                                    <th width="50%" style="text-align: left">{{$t('zinl.HTA Agency conclusion on Economic Evidence')}}</th>
                                    <td>{{data.zinl['hta_agency_conclusion_on_economic_evidence']  ? $t( 'zinl.conclusion_economic_' + data.zinl['hta_agency_conclusion_on_economic_evidence']) : "-"}}</td>
                                </tr>
                            </tbody>
                        </table>                    
                        <br/>
                        <span v-html="data.zinl['conclusion_economic_text']"></span>           
                    </template>    


                    <!-- 'sub_detail/evaluation_economic.ctp'  -->
                    <EvaluationEconomic v-else-if="key == 'detail_EvaluationEconomic' && item.show" :data="data.zinl.evaluation_economic" />
                    
                    <!-- 'sub_detail/EconomicEvaluation.ctp' -->
                    <EconomicEvaluation v-else-if="key == 'detail_EconomicEvaluation' && item.show" :data="data.data_links['EconomicEvaluation']" />
                 
                    <!--BudgetImpact start-->
                    <BudgetImpact :data="data.zinl['budget_impacts']" v-else-if="key == 'detail_BudgetImpact' && item.show" /> 
                    <!--BudgetImpact end-->
                    
                    <!-- detail_final_decision_moh -->                    
                    <table  v-else-if="key == 'detail_final_decision_moh' && item.show" class="comparateur2 table-result text-left border-top-gray" >
                        <tbody>                            
                            <tr v-if="data.zinl['decision']">
                                <th width="50%" style="text-align: left">{{ $t('zinl.Decision') }}</th>
                                <td>{{ $t('zinl.decision_' + data.zinl['decision']) }}</td>
                            </tr>                                                        
                            <tr v-if="data.zinl['pharmacy_purchase_price']">
                                <th width="50%" style="text-align: left">{{ $t('zinl.Pharmacy purchase price') }}</th>
                                <td>{{ data.zinl['pharmacy_purchase_price'] }}</td>
                            </tr>  
                            <tr v-if="data.zinl['quantity']">
                                <th width="50%" style="text-align: left">{{ $t('zinl.Packaging') }}</th>
                                <td>{{ data.zinl['quantity'] }}</td>
                            </tr>
                            <tr v-if="data.zinl['moh_date']">
                                <th width="50%" style="text-align: left">{{ $t('zinl.Date') }}</th>
                                <td>{{ DDMMMYY(data.zinl['moh_date']) }}</td>
                            </tr>   
                            <tr v-if="data.zinl['moh_source']">
                                <th width="50%" style="text-align: left">{{ $t('zinl.Source') }}</th>
                                <td>{{ data.zinl['moh_source'] }}</td>
                            </tr>  
                            <tr v-if="data.zinl['moh_commercial_arrangement']">
                                <th width="50%" style="text-align: left">{{ $t('zinl.Commercial arrangement') }}</th>
                                <td>{{ data.zinl['moh_commercial_arrangement'] }}</td>
                            </tr>  
                            <tr v-if=" data.zinl['moh_date_start']  || data.zinl['moh_date_end'] ">
                                <th width="50%" style="text-align: left">{{ $t('zinl.Start date - End date') }}</th>
                                <td>                                     
                                    <span v-html="date_moh(data.zinl)"></span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    
                    <KeyDocument v-else-if="key=='detail_key_documents' && item.show" :data="data.key_documents" :list_document_exist="data.list_document_exist"/>

                     <!-- Section Detail End -->
 
                </div>
            </template>

            <!-- Link Section Start-->       
            <LinkAgency v-for="(data_agency,Agency) in data.data_links" :data="data_agency" :link_agency="Agency" :key="Agency" />
            <!-- Link Section End -->

        </div>
        <!-- Right Section End -->
    </div>
</template>

<script>
import LeftSection from '../LeftSection.vue'
import TreatmentLine from '../treatment_line.vue'
import EvaluationEconomic from '../evaluation_economic.vue'
import EconomicEvaluation from '../economic_evaluation.vue'
import Conclusion from '../conclusion.vue'
import BudgetImpact from '../budget_impact.vue'
import LinkAgency from '../LinkAgency.vue'
import KeyDocument from '../key_document.vue'
import InformationGeneral from '../InformationGeneral.vue'
import Indication from '../Indication.vue'
import { DDMMMYY, check_detail_conclusion, key_documents } from '../../../utils'

export default {
    name : 'zinl',
    components : {
        LeftSection,
        TreatmentLine,
        EvaluationEconomic,
        EconomicEvaluation,
        Conclusion,
        BudgetImpact,
        LinkAgency,
        KeyDocument,
        InformationGeneral,
        Indication
    },
    data(){
        return {
            list_check_box : {
                // show -> show detail, 
                // enable -> show [check box] on left box, 
                // orange_text -> if true: color of title text is orange
                detail_information_general : {'title' : 'information generale', 'show' : true, 'enable' : false, 'orange_text':false},
                detail_indication : {'title' : 'zinl.indication_evaluated', 'show' : true, 'enable' : false, 'orange_text':false},
                treatment_line :{'title' : 'population', 'show' : true, 'enable' : false, 'orange_text':false},
                detail_conclusion3 : {'title' : 'Conclusion', 'show' : true, 'enable' : false, 'orange_text':false},
                detail_Conclusion : {'title' : 'Rationale and Commentary', 'show' : true, 'enable' : false, 'orange_text':true},
                detail_clinical_rationale_safety_concerns  : {'title' : 'zinl.clinical_rationale', 'show' : true, 'enable' : false, 'orange_text':false},
                detail_economic_rationale_same_price : {'title' : 'zinl.economic_rationale', 'show' : true, 'enable' : false, 'orange_text':false},
                detail_conclusion2 : {'title' : 'zinl.conclusion_and_cost', 'show' : true, 'enable' : false, 'orange_text':false},
                detail_EvaluationEconomic : {'title' : 'Economic evaluation', 'show' : true, 'enable' : false, 'orange_text':true},                
                detail_EconomicEvaluation: {'title' : 'Economic evaluation', 'show' : true, 'enable' : false, 'orange_text':true},
                detail_BudgetImpact: {'title' : 'Budget impact', 'show' : true, 'enable' : false, 'orange_text':true},
                detail_final_decision_moh: {'title' : 'zinl.Final Decision MoH', 'show' : true, 'enable' : false, 'orange_text':false}, 
                detail_key_documents : {'title' : 'Key documents', 'show' : true, 'enable' : false, 'orange_text':false },       
            },
        }
    },
    created(){
        // condition to show check box in left section [enable]
        if(this.data && this.data.zinl){
            
            if(this.data.ma_date){ 
                this.list_check_box['detail_information_general'].enable = true
            }
            if (this.data['indication_en']){
                this.list_check_box['detail_indication'].enable = true
            }

            if (this.data.zinl.treatment_line ||this.data.zinl.treatment_specificity || this.data.zinl.treatment_administration ){
                this.list_check_box['treatment_line'].enable = true
            }
            if (this.data.zinl.hta_agency_conclusion_on_economic_evidence 
            || this.data.zinl.condition_for_approval 
            || this.data.zinl.recommended_indication  )  {                    
                this.list_check_box['detail_conclusion3'].enable = true

            }
            if(this.check_detail_conclusion(this.data, this.$i18n.locale)) { 

                this.list_check_box['detail_Conclusion'].enable = true

            }
            
            if (this.data.zinl['zinl_clinical_rationale'].length > 0  ){ 
                this.list_check_box['detail_clinical_rationale_safety_concerns'].enable = true
            }

            if (this.data.zinl['zinl_economic_rationale'].length > 0  ){ 
                this.list_check_box['detail_economic_rationale_same_price'].enable = true
            }

            if (this.data.zinl.conclusion 
            || this.data.zinl.conclusion_economic_text 
            || this.data.zinl.hta_agency_conclusion_on_economic_evidence  )  {                    
                this.list_check_box['detail_conclusion2'].enable = true 
            }
                                
                if(this.data.zinl.evaluation_economic.length > 0) { 
                this.list_check_box['detail_EvaluationEconomic'].enable = true
            }
            
            if(this.data.data_links && this.data.data_links['EconomicEvaluation'] && this.data.data_links['EconomicEvaluation'].length > 0) { 
                this.list_check_box['detail_EconomicEvaluation'].enable = true
                this.list_check_box['detail_EvaluationEconomic'].enable = false                    
            }
            if (this.data.zinl['budget_impacts'].length){
                this.list_check_box['detail_BudgetImpact'].enable = true                
            }

            if (this.data.zinl.decision
            || this.data.zinl.pharmacy_purchase_price
            || this.data.zinl.quantity
            || this.data.zinl.moh_date
            || this.data.zinl.moh_source
            || this.data.zinl.moh_commercial_arrangement
            || this.data.zinl.moh_date_start
            || this.data.zinl.moh_date_end

            ) {
                this.list_check_box['detail_final_decision_moh'].enable = true 
            }
            
            if(key_documents(this.data.key_documents) && key_documents(this.data.key_documents).length > 0)
            {
                    this.list_check_box['detail_key_documents'].enable = true
            }
        }
    },      
    methods :{
        DDMMMYY,
        check_detail_conclusion,
        key_documents,
        date_moh(param){
            let txt = ''
            txt += param['moh_date_start'] ? this.DDMMMYY(param['moh_date_start']) : ""
            txt += ' <strong style="color: black;">-</strong> '
            txt += param['moh_date_end'] ? this.DDMMMYY(param['moh_date_end']) : ""
            return txt
        }

    },
    computed : {
        data(){
            return this.$store.getters['detail/data']
        },
        show_block : {
            set(val) { this.$store.dispatch('detail/set_show_block', val)},
            get() {return this.$store.getters['detail/show_block']}
        },
        view_enabled() {  
            return this.$store.getters['detail/view_enabled']        
        } 
    },
}
</script>

<style scoped>
a{
    color: #000;
}

.box-toggle.icon-arrow-updown {
    font-size: 16px;
    right: -14px;
    color: #232426;
    margin-left:5px;
}
.box-toggle a:hover i {
    color: #ff6300;
}
.fa-angle-up {
    color : #ff6300;
}


</style>